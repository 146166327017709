<div class="row">
  <div class="col-xs-12">
    <h3>Meldkamerspel Mission Generator</h3>
    <p>
      Release: <span class="badge badge-danger">{{ version }}</span>
    </p>
  </div>
</div>
<hr />
<div class="row">
  <div class="col-xs-12 col-md-6">
    <div class="alert alert-warning">
      <p>
        <span class="glyphicon glyphicon-lock"></span>
        <b>End of Life</b>
        <br>
        <br>
        <strong>Let Op!</strong> Deze versie van Mission Generator is End of Life, en zal binnenkort niet meer bereikbaar zijn!
        <br>
        <br>
        De nieuwe Mission Generator is te vinden via <a href="https://app.missiongenerator.nl">https://app.missiongenerator.nl</a>. Hiervoor is een apart Keycloak account nodig.
      </p>
    </div>
  </div>
  <div class="col-xs-12 col-md-6">
    <!-- Content -->
    <h4>Facebook Calendar (Lampe)</h4>
    <div class="alert alert-info">
      <p>
        <span class="glyphicon glyphicon-lock"></span>
        <b>Uitgefaseerd</b>
        <br>
        Gebruik voor het plannen van activaties het nieuwe Mission Generator platform via <a href="https://app.missiongenerator.nl">https://app.missiongenerator.nl</a>
      </p>
    </div>
    <br />
  </div>
</div>
